import { createStore } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./LoginUser";
const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistCombineReducers(persistConfig, {
  user: userReducer,
});

const storedata = createStore(persistedReducer);
const persistor = persistStore(storedata);

export { storedata, persistor };
